import { useCallback, useMemo, useState } from 'react';
import { removeItem, getItem, setItem } from './utils';

const useLocalStorage = <T>(key: string, defaultValue = null as T | null) => {
  const [cacheCounter, setCacheCounter] = useState(0);

  const getValue = useCallback((): T | null => {
    return getItem<T>(key) || defaultValue;

    // Include cacheCounter in the deps array so that a new function is returned anytime setValue is called.
    // This allows getValue to be used in dependency arrays and properly trigger updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheCounter, defaultValue, key]);

  const setValue = useCallback(
    (value: T | null) => {
      setCacheCounter(i => i + 1);
      if (value === null) {
        removeItem(key);
      } else {
        setItem(key, value);
      }
    },
    [key]
  );

  return useMemo(() => ({ getValue, setValue }), [getValue, setValue]);
};

export default useLocalStorage;
