const getStarData = (rating = 0): { numHighlightedStars: number; includeHalfStar: boolean } => {
  let numHighlightedStars = 0;
  let includeHalfStar = false;

  /*
      This logic mimics the StarRating logic in the monolith, which utilized @material-ui/lab/Rating.
      - Number of stars logic:
        x -> x.2: round down to x
        x.3 -> x.7: round down to x and include a half-star
        x.8 -> x + 1: round up to x + 1
  
        Ex: Rating of 4.4 should result in 4 and a half stars
    */

  if (rating <= 0) {
    return { numHighlightedStars: 0, includeHalfStar: false };
  }

  if (rating >= 5) {
    return { numHighlightedStars: 5, includeHalfStar: false };
  }

  const decimalPart = Number(rating.toString().split('.')[1]);

  if (!decimalPart) {
    return { numHighlightedStars: rating, includeHalfStar: false };
  }

  if (decimalPart > 7) {
    numHighlightedStars = Math.floor(rating) + 1;
  }

  if (decimalPart >= 3 && decimalPart <= 7) {
    numHighlightedStars = Math.floor(rating);
    includeHalfStar = true;
  }

  if (decimalPart < 3) {
    numHighlightedStars = Math.floor(rating);
  }

  return { numHighlightedStars, includeHalfStar };
};

export default getStarData;
