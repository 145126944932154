import { useMemo } from 'react';
import classNames from 'classnames';
import StarFilled from '../../icons/StarFilled';
import StarHalf from '../../icons/StarHalf';
import getStarData from './utils/getStarData';
import styles from './styles/styles.module.scss';

type StarRatingProps = {
  className?: string;
  rating: number;
  shouldShowRating?: boolean;
  ratingSide?: 'left' | 'right';
  variant?: 'simple' | 'complex';
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
};

const StarRating = ({
  className,
  rating,
  variant = 'complex',
  shouldShowRating = false,
  size = 'sm',
  ratingSide = 'right',
  testId
}: StarRatingProps): JSX.Element => {
  const iconSize = useMemo(() => {
    if (size === 'lg') {
      return 'md';
    } else if (size === 'md') {
      return 'sm';
    } else {
      return 'xs';
    }
  }, [size]);

  const renderSimpleRating = () => <StarFilled color="--color-brand-neon" size={iconSize} />;

  const renderComplexRating = () => {
    const totalStars = 5;
    const { numHighlightedStars, includeHalfStar } = getStarData(rating);

    return (
      <>
        {Array(totalStars)
          .fill(1)
          .map((_, index) => {
            if (numHighlightedStars > index) {
              return <StarFilled color="--color-brand-neon" size={iconSize} key={`FILLED_STAR_${index}`} />;
            }

            if (numHighlightedStars + 1 > index && includeHalfStar) {
              return <StarHalf color="--color-brand-neon" key={index} size={iconSize} />;
            }

            return <StarFilled color="--color-text-disabled" size={iconSize} key={`DISABLED_STAR_${index}`} />;
          })}
      </>
    );
  };

  return (
    <div
      className={classNames(styles.container, styles[size], styles[variant], styles[ratingSide], className)}
      data-testid={testId}
      itemProp="reviewRating"
    >
      <div className={styles.stars}>{variant === 'simple' ? renderSimpleRating() : renderComplexRating()}</div>
      {shouldShowRating && <span>{rating}</span>}
    </div>
  );
};

export default StarRating;
