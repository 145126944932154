import { COLOR_BRAND_DARK, COLOR_BRAND_NEON, COLOR_BRAND_PRIMARY, COLOR_NEUTRAL_WHITE, COLOR_PLUS_300, COLOR_PLUS_600 } from '../../tokens';
import { Size } from '../../types';

type LogoSize = Size<'xs' | 'sm' | 'md' | 'lg'>;
type Color = 'dark' | 'light' | 'neon' | 'primary';
type Variant = 'symbol' | 'logomark' | 'plus-logomark';

type LogoProps = {
  variant: Variant;
  size: LogoSize;
  color?: Color; // color is optional so we can pass in a className to control light / dark mode fills
  className?: string;
};

const colorToToken: Record<Color, string> = {
  dark: COLOR_BRAND_DARK,
  light: COLOR_NEUTRAL_WHITE,
  neon: COLOR_BRAND_NEON,
  primary: COLOR_BRAND_PRIMARY
};

const plusColorToToken: Record<Color, string> = {
  dark: COLOR_PLUS_600,
  light: COLOR_PLUS_300,
  neon: COLOR_NEUTRAL_WHITE,
  primary: COLOR_BRAND_PRIMARY
};

// size maps to height
export const logoSizeToPx: Record<LogoSize, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48
};

const variantSizeRatio = {
  // https://www.figma.com/file/jwAiqQtGYze2VIHeYXHP58/Denali-Components?node-id=2977%3A23811
  // width / height ratios used to calculate width
  symbol: 1.313, // 63 / 48
  logomark: 5.458, // 262 / 48,
  'plus-logomark': 6.083 // 292 / 48
};

const getWidth = (height: number, ratio: number): number => Math.round(height * ratio * 10) / 10;

export const getSvgProperties = (variant: LogoProps['variant'], size: LogoSize): { width: number; height: number; viewBox: string } => {
  const height = logoSizeToPx[size];
  const width = getWidth(height, variantSizeRatio[variant]);
  // viewbox takes the largest size
  const viewBox = `0, 0, ${getWidth(logoSizeToPx.lg, variantSizeRatio[variant])} ${logoSizeToPx.lg}`;

  return { width, height, viewBox };
};

type LogoInnerProps = {
  variant: Variant;
  color?: Color;
};

const LogoInner = ({ variant, color }: LogoInnerProps): JSX.Element => {
  const fill = color ? colorToToken[color] : undefined;
  const whiteFillWhenNeon = color ? colorToToken[color === 'neon' ? 'light' : color] : undefined;
  const plusFill = color ? plusColorToToken[color] : undefined;

  switch (variant) {
    case 'symbol':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.265 14.825c-1.688-3.224-2.993-4.761-4.687-4.761-1.891 0-2.724 1-3.865 2.79-.92 1.2-1.809 2.843-3.289 2.68-1.562-.165-2.466-3.857-3.645-6.947C33.162 4.376 32.23 0 29.544 0c-1.532 0-2.872 1.395-4.95 4.515L3.13 37.428C.664 41.549-1.4 44.42 1.212 47.12c3.07 2.953 10.278-2.215 15.212-5.333 4.933-3.118 9.976-6.153 17.102-5.99 9.593.22 14.253 9.846 20.063 11.678 3.947 1.258 7.565-.191 8.333-4.266.452-2.24-.332-4.414-1.415-6.733L50.265 14.825Zm-.658 19.305c-1.946 1.449-4.66-.876-6.414-1.86-1.863-1.04-5.015-3.528-10.661-3.446-4.605.055-7.1 1.778-9.84 3.637-5.948 4.048-11.32 8.752-12.855 6.318-.986-1.56 1.672-4.677 7.921-14.412 4.44-6.92 7.568-12.444 9.785-12.444 2.45 0 2.588 2.404 2.96 4.868.705 3.925 2.623 5.968 5.115 6.29 2.793.378 5.207-1.966 7.126-1.941 1.792.038 2.971 2.633 4.451 5.34 1.875 3.364 3.832 6.583 2.412 7.65Z"
          {...(fill ? { fill } : {})}
        />
      );
    case 'logomark':
      return (
        <>
          <path
            d="M214.768 7.809c2.631 0 4.199 1.744 4.199 4.265s-1.568 4.128-4.199 4.128c-2.768 0-4.246-1.697-4.246-4.128s1.478-4.265 4.246-4.265ZM218.479 19.516h-7.472V47.18h7.472V19.516ZM142.834 15.367v31.817h8.065V15.367h10.968V10.07h-28.605v5.296h9.572Z"
            fill={whiteFillWhenNeon}
          />
          <path
            d="M175.874 26.847a12.454 12.454 0 0 0-3.163-.42c-3.69 0-5.746 2.202-5.746 6.712v14.047h-7.434V19.51h7.011v7.599c1.372-5.188 4.955-8.336 9.332-8.385v8.123ZM231.704 47.18V10.124h-7.423v37.058h7.423ZM255.251 27.846c-.415-.766-2.31-3.832-6.395-3.832-3.849 0-4.736 1.767-4.62 2.946.179 1.532 1.008 2.297 5.922 3.36 6.693 1.354 10.719 3.535 10.719 8.84 0 6.128-5.685 8.839-12.614 8.839-3.612 0-9.77-1.002-12.789-6.423l5.27-3.535s2.666 4.126 7.402 4.126c4.383 0 4.798-1.945 4.856-2.652.12-1.18-.236-2.417-5.212-3.538-7.046-1.531-11.251-3.124-11.251-8.958 0-3.005 2.666-8.426 12.139-8.426 8.809 0 11.322 4.842 11.821 5.803.038.073.064.123.081.148l-5.329 3.299v.003Z"
            fill={whiteFillWhenNeon}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.069 31.229c-7.922 1.534-12.827 3.485-12.827 9.625l-.003-.003c0 4.487 3.428 7.145 8.57 7.145 4.847 0 8.1-2.364 9.873-6.437.236 4.428 2.245 6.437 6.739 6.437 2.129 0 3.016-.413 4.021-1.063v-4.961c-.532.236-.946.413-1.714.413-1.892 0-2.246-.768-2.246-3.365v-9.742c0-7.203-4.494-10.688-11.586-10.688-6.264 0-10.579 2.894-13.003 9.154l6.503 1.89c1.004-3.13 2.482-5.256 6.146-5.256 3.665 0 5.084 1.476 5.084 3.307 0 2.245-2.423 2.894-5.557 3.544Zm5.794 1.95v1.712c0 3.072-2.424 7.678-7.508 7.678-2.838 0-4.02-1.063-4.02-3.01 0-2.245 1.538-3.25 6.266-4.37 3.016-.65 4.61-1.183 5.262-2.01ZM69.963 47.184l.001-.003h6.429l2.426-7.756h16.025l2.532 7.756h8.541l-13.02-37.107H82.72L69.965 47.18h-.004l.003.003ZM85.357 18.67l-4.85 15.463h12.6l-5.113-15.463-1.317-4.14h-.105l-1.212 4.14h-.003Z"
            fill={whiteFillWhenNeon}
          />
          <path d="M108.743 47.184v-37.11h7.435v37.11h-7.435ZM121.983 47.184v-37.11h7.434v37.11h-7.434Z" fill={whiteFillWhenNeon} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.213 14.825c-1.689-3.224-2.993-4.761-4.687-4.761-1.891 0-2.725 1-3.865 2.79-.92 1.2-1.809 2.843-3.289 2.68-1.562-.165-2.467-3.857-3.645-6.947C34.11 4.376 33.177 0 30.492 0c-1.532 0-2.873 1.395-4.95 4.515L4.078 37.428C1.611 41.549-.452 44.42 2.16 47.12c3.07 2.953 10.278-2.215 15.211-5.333 4.934-3.118 9.977-6.153 17.103-5.99 9.593.22 14.253 9.846 20.063 11.678 3.947 1.258 7.565-.191 8.332-4.266.453-2.24-.331-4.414-1.414-6.733L51.213 14.825Zm-.658 19.305c-1.946 1.449-4.66-.876-6.414-1.86-1.864-1.04-5.016-3.528-10.662-3.446-4.604.055-7.098 1.778-9.84 3.637-5.947 4.048-11.319 8.752-12.854 6.318-.987-1.56 1.672-4.677 7.921-14.412 4.44-6.92 7.568-12.444 9.785-12.444 2.45 0 2.587 2.404 2.96 4.868.705 3.925 2.623 5.968 5.114 6.29 2.793.378 5.208-1.966 7.127-1.941 1.792.038 2.97 2.633 4.45 5.34 1.876 3.364 3.832 6.583 2.413 7.65Z"
            fill={fill}
          />
        </>
      );
    case 'plus-logomark':
      return (
        <>
          <path d="M282.439 18.44h-6.146v9.365h-9.366v5.854h9.366v9.659h6.146v-9.659h9.366v-5.854h-9.366V18.44Z" fill={plusFill} />
          <path
            d="M213.768 7.809c2.631 0 4.199 1.744 4.199 4.265s-1.568 4.128-4.199 4.128c-2.768 0-4.246-1.697-4.246-4.128s1.478-4.265 4.246-4.265ZM217.479 19.516h-7.472V47.18h7.472V19.516ZM141.834 15.367v31.817h8.065V15.367h10.968V10.07h-28.604v5.296h9.571Z"
            fill={whiteFillWhenNeon}
          />
          <path
            d="M174.874 26.847a12.453 12.453 0 0 0-3.162-.42c-3.691 0-5.747 2.202-5.747 6.712v14.047h-7.434V19.51h7.011v7.599c1.372-5.188 4.955-8.336 9.332-8.385v8.123ZM230.704 47.18V10.124h-7.423v37.058h7.423ZM254.251 27.846c-.415-.766-2.31-3.832-6.395-3.832-3.849 0-4.736 1.767-4.619 2.946.178 1.532 1.007 2.297 5.921 3.36 6.693 1.354 10.72 3.535 10.72 8.84 0 6.128-5.686 8.839-12.615 8.839-3.612 0-9.77-1.002-12.789-6.423l5.27-3.535s2.666 4.126 7.402 4.126c4.383 0 4.798-1.945 4.856-2.652.12-1.18-.236-2.417-5.212-3.538-7.046-1.531-11.251-3.124-11.251-8.958 0-3.005 2.666-8.426 12.139-8.426 8.809 0 11.322 4.842 11.821 5.803.038.073.064.123.081.148l-5.329 3.299v.003Z"
            fill={whiteFillWhenNeon}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M190.069 31.229c-7.921 1.534-12.827 3.485-12.827 9.625l-.003-.003c0 4.487 3.428 7.145 8.57 7.145 4.847 0 8.1-2.364 9.873-6.437.236 4.428 2.245 6.437 6.739 6.437 2.129 0 3.016-.413 4.021-1.063v-4.961c-.532.236-.946.413-1.714.413-1.892 0-2.246-.768-2.246-3.365v-9.742c0-7.203-4.494-10.688-11.586-10.688-6.264 0-10.579 2.894-13.003 9.154l6.503 1.89c1.004-3.13 2.482-5.256 6.147-5.256 3.664 0 5.083 1.476 5.083 3.307 0 2.245-2.423 2.894-5.557 3.544Zm5.794 1.95v1.712c0 3.072-2.424 7.678-7.508 7.678-2.838 0-4.02-1.063-4.02-3.01 0-2.245 1.538-3.25 6.266-4.37 3.016-.65 4.611-1.183 5.262-2.01ZM68.963 47.184l.001-.003h6.43l2.426-7.756h16.024l2.532 7.756h8.541l-13.02-37.107H81.72L68.964 47.18h-.004l.003.003ZM84.357 18.67l-4.85 15.463h12.6l-5.113-15.463-1.317-4.14h-.105l-1.212 4.14h-.003Z"
            fill={whiteFillWhenNeon}
          />
          <path d="M107.744 47.184v-37.11h7.434v37.11h-7.434ZM120.983 47.184v-37.11h7.434v37.11h-7.434Z" fill={whiteFillWhenNeon} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.31 14.825c-1.688-3.224-2.993-4.761-4.687-4.761-1.89 0-2.724 1-3.864 2.79-.921 1.2-1.81 2.843-3.29 2.68-1.562-.165-2.466-3.857-3.645-6.947C33.208 4.376 32.276 0 29.59 0c-1.532 0-2.872 1.395-4.95 4.515L3.177 37.428c-2.467 4.121-4.53 6.992-1.919 9.692 3.07 2.953 10.279-2.215 15.212-5.333 4.933-3.118 9.977-6.153 17.103-5.99 9.593.22 14.252 9.846 20.063 11.678 3.947 1.258 7.565-.191 8.332-4.266.452-2.24-.332-4.414-1.414-6.733L50.31 14.825Zm-.658 19.305c-1.946 1.449-4.659-.876-6.413-1.86-1.864-1.04-5.016-3.528-10.662-3.446-4.605.055-7.099 1.778-9.84 3.637-5.947 4.048-11.32 8.752-12.854 6.318-.987-1.56 1.672-4.677 7.92-14.412 4.441-6.92 7.568-12.444 9.786-12.444 2.45 0 2.587 2.404 2.96 4.868.704 3.925 2.623 5.968 5.114 6.29 2.793.378 5.208-1.966 7.126-1.941 1.793.038 2.971 2.633 4.451 5.34 1.875 3.364 3.832 6.583 2.412 7.65Z"
            fill={fill}
          />
        </>
      );
    default:
      return <></>;
  }
};

const Logo = ({ variant, size, color, className }: LogoProps): JSX.Element => {
  const { width, height, viewBox } = getSvgProperties(variant, size);

  return (
    <svg className={className} width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <title>{variant === 'plus-logomark' ? 'AllTrails+' : 'AllTrails'}</title>
      <LogoInner variant={variant} color={color} />
    </svg>
  );
};

export default Logo;
