import Icon from '../components/Icon';

const StarHalf = Icon(
  'star-half',
  <>
    <path d="M5.395 21.473 12 18l.216.114.008-17.601a.167.167 0 0 0-.031-.108.25.25 0 0 0-.417.05l-3.303 6.69L1.09 8.22a.25.25 0 0 0-.139.426l5.344 5.21-1.262 7.354a.25.25 0 0 0 .363.264Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.216 18.114 6.39 3.358a.25.25 0 0 0 .362-.263l-1.262-7.355 5.344-5.209a.25.25 0 0 0-.139-.426l-7.384-1.073L12.224.454a.246.246 0 0 0-.031-.049c.024.03.031.07.031.108l-.008 17.6Z"
      fill="#F1F1EE"
    />
  </>
);

export default StarHalf;
