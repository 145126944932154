import Icon from '../components/Icon';

const Check = Icon(
  'check',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.026 7.034a.75.75 0 0 0-1.052-1.068L9.76 16.033 5.02 11.46a.75.75 0 1 0-1.04 1.08l5.265 5.08a.75.75 0 0 0 1.047-.005l10.734-10.58Z"
    />
  </>
);

export default Check;
