import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * A convenience hook for managing a component that renders in and out.
 * @param shouldRender Whether the component should be rendered or not
 * @param onAnimationOutEnd Callback to be called after the animation out ends
 * @returns isAnimating - whether the component is in the middle of an animation, either in or out.
 *          isVisible - whether the component is fully visible or not - updated at the end of the animation.
 *          onAnimationEnd - callback to pass to onAnimationEnd, assuming the component is using css animation
 */
const useAnimateInOutComponent = (shouldRender: boolean, onAnimationOutEnd?: () => void) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible !== shouldRender) {
      setIsAnimating(true);
    } else {
      // Should only happen if the component is passed a new shouldRender value before the animation ends
      setIsAnimating(false);
    }
    // Just want shouldRender here since onAnimationEnd updates isVisible
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender]);

  const onAnimationEnd = useCallback(() => {
    setIsAnimating(false);
    setIsVisible(!isVisible);
    if (onAnimationOutEnd && isVisible) {
      onAnimationOutEnd();
    }
  }, [isVisible, onAnimationOutEnd]);

  return useMemo(() => ({ isAnimating, isVisible, onAnimationEnd }), [isAnimating, isVisible, onAnimationEnd]);
};

export default useAnimateInOutComponent;
