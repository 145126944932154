/**
 * localStorage can only be used client-side and if the user's permissions do not block it
 *
 * @returns true if localStorage is accessible, false if not
 */
export const canUseLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const test = 'alltrails-local-storage-test';
    try {
      window.localStorage?.setItem(test, test);
      window.localStorage?.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const getItem = <T>(key: string): T | null => {
  if (canUseLocalStorage()) {
    const value = window.localStorage?.getItem?.(key);
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value) as T;
    } catch (error) {
      // JSON.parse can fail for basic strings that were already put into storage before this hook was created
      return JSON.parse(JSON.stringify(value)) as T;
    }
  }
  return null;
};

export const setItem = <T>(key: string, value: T) => {
  if (canUseLocalStorage()) {
    window.localStorage?.setItem?.(key, JSON.stringify(value));
  }
};

export const removeItem = (key: string) => {
  if (canUseLocalStorage()) {
    window.localStorage?.removeItem?.(key);
  }
};
